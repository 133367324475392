
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import PAGES from '@/modules/common/constants/pages.constant';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesPriceHistoryService, { RatesPriceHistoryServiceS } from '@/modules/rates/price-history/rates-price-history.service';
import { DocumentSettings } from '../types';

@Component
export default class RatesPriceHistoryFilters extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(ClusterRatesServiceS) private clusterRatesService!: ClusterRatesService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(RatesPriceHistoryServiceS) private ratesPriceHistoryService!: RatesPriceHistoryService;

    @Prop({ type: Boolean, default: false })
    isCluster!: boolean;

    @Prop({ default: null })
    private customSettings!: RatesSettingsModel | null;

    @Prop({ default: false })
    skeleton!: boolean;

    get hotelSettings() {
        const { los, pos } = this.documentFiltersService.settings;
        const { currentCompset } = this.compsetsService;
        const compsetType = currentCompset && this.$tc(`compset.${currentCompset.type}`);
        const compSet = currentCompset ? `${currentCompset.name} (${compsetType})` : '';
        const {
            provider, roomTypeId, mealTypeId, numberOfGuests, priceType,
        } = this.customSettings || this.ratesPriceHistoryService.ratesSettings || {};

        return {
            los,
            pos,
            compSet,
            provider,
            roomTypeId,
            mealTypeId,
            numberOfGuests,
            priceType,
        } as DocumentSettings;
    }

    get clusterSettings() {
        const { los } = this.documentFiltersService.settings;
        const hotelId = Number(this.$route.params.hotelId);
        const { compsetId } = this.$route.params;
        const clusterCompset = this.clusterService.getCompset(compsetId, hotelId);
        const pos = clusterCompset ? clusterCompset.mainPos : null;
        const compsetType = clusterCompset && this.$tc(`compset.${clusterCompset.type}`);
        const compSet = clusterCompset ? `${clusterCompset.name} (${compsetType})` : ' - ';
        const {
            provider, priceType, roomTypeId, mealTypeId,
        } = this.customSettings || this.clusterRatesService.currentSettings || {};

        return {
            los,
            pos,
            compSet,
            provider: this.$route.path.includes(PAGES.DILITE) ? 'all' : provider,
            priceType,
            roomTypeId,
            mealTypeId,
        } as DocumentSettings;
    }

    get settings() {
        return this.isCluster ? this.clusterSettings : this.hotelSettings;
    }

    get month() {
        return this.documentFiltersService.storeState.settings.month;
    }

    get roomType() {
        const { roomTypeId } = this.settings;
        const { rooms } = this.roomTypesService;
        const currentRoom = rooms.find(room => room.id === roomTypeId);

        if (!currentRoom) {
            return '';
        }

        return currentRoom.name;
    }

    get mealType() {
        const { mealTypeId } = this.settings;
        const { mealTypes } = this.mealTypesService;
        const currentMealType = mealTypes.find(mealType => mealType.id === mealTypeId);

        if (!currentMealType) {
            return '';
        }

        return currentMealType.displayName;
    }

    getProviderLabel(providerName: string) {
        return this.providersService.getProviderLabel(providerName);
    }
}
